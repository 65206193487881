/* eslint-disable no-param-reassign */
/* global dataLayer */
import { PureComponent, lazy, Suspense, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { differenceInMonths } from 'date-fns';
import NoSSR from 'react-no-ssr';
import { clientErrorTracker, trans } from '@spotahome/soyuz/client';
import { Mediator } from '@spotahome/soyuz-mediator';
import Metrics from '@spotahome/soyuz-tracking';
import {
  MediaQuery,
  FabButton,
  FooterSimplified,
  cityToggleConfig,
  SEOSnippet,
  SeoSnippetShape,
  FilterUtils,
  SearchBreadcrumbs
} from '@spotahome/ui-library';
import { CustomDimensionsManager } from '@spotahome/ecommerce-tracking';

import internalLinkingModule from '@spotahome/marketplace-common/src/shapes/internalLinking';
import mapPointShape from '@spotahome/marketplace-common/src/shapes/mapPoint';

import Bell from '@spotahome/marketplace-common/src/components/icons/Bell';
import InternalLinkingModules from '@spotahome/marketplace-common/src/components/internalLinking/InternalLinkingModules';
import HeaderContainer from '@spotahome/marketplace-common/src/components/HeaderContainer';
import HomecardMap from '@spotahome/marketplace-common/src/components/HomecardMap';
import RefreshIcon from '@spotahome/marketplace-common/src/components/icons/Refresh';
import AlertButton from '@spotahome/marketplace-common/src/components/AlertButton';
import AppliedFiltersModal from '@spotahome/marketplace-common/src/components/AppliedFiltersModal';
import AlertInPastModal from '@spotahome/marketplace-common/src/components/AlertInPastModal';

import AdUrlService from '@spotahome/marketplace-common/src/utils/ad-url-service';
import { removeSeoFilters } from '@spotahome/marketplace-common/src/utils/seo-mode';

import {
  setPoiRequest,
  deletePoiRequest
} from '@spotahome/marketplace-common/src/api/poi-client';

import { getMarkersQuery } from '@spotahome/marketplace-common/src/graphql/queries/markers';
import getGqlClient from '@spotahome/marketplace-common/src/graphql/client';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import {
  HOMECARD_MAP_CLOSE,
  HOMECARD_MAP_OPEN
} from '@spotahome/marketplace-common/src/channels';

import { shiftMarkers } from '../../utils/markerShifter';

import poiShape from '../../shapes/poi';

import Paginator from '../Paginator';
import SEOContent from '../SEOContent';
import SearchBanner from '../SearchBanner';
import SearchTitle from '../SearchTitle';
import FiltersHeader from '../FiltersHeader';
import FiltersExposed from '../FiltersExposed';

import PreFilters from '../PreFilters';
import HomeCardList from '../HomeCardList';
import MapListSwitcher from '../MapListSwitcher';
import Filters from '../Filters';
import MapPlaceholder from '../MapPlaceholder';

import CardPaginator from '../../lib/card-paginator';

import ValueProposition from '../ValueProposition';
import RemoveFilters from '../RemoveFilters/RemoveFilters';

const Map = lazy(() => import('../Map'));
const PlacesAutocomplete = lazy(() =>
  import('@spotahome/marketplace-common/src/components/PlacesAutocomplete')
);

// If a POI is defined, the list of markers will be retrieved ordered by distance
// and the frontend will order by relevance each set defined by this const.
const MAP_ID = 'themap';
const PLACE_SELECTED_EVENT_CATEGORY = 'interactive-map-add-reference-point';
const PLACE_SELECTED_DIFFERENT_CITY_EVENT_CATEGORY =
  'interactive-map-search-different-city';
const HOTJAR_POTENTIAL_PERMANENT_BOOKING = 'potential_permanent_booking';

const VIEW_MAP_LIST_EVENT_CATEGORY = 'Search';
const VIEW_MAP_LIST_EVENT_ACTION = 'View map-list toggle';
const VIEW_MAP_LIST_EVENT_VIEW_MAP_TAG = 'View map';
const VIEW_MAP_LIST_EVENT_VIEW_LIST_TAG = 'View list';

const INITIAL_STATE = {
  maxTotalResults: 0,
  isFiltersHidden: true,
  canUpdateBoundaries: false
};

const {
  isMobileScreen,
  isPhabletScreen,
  isTabletScreen,
  isBiggerThanTablet,
  getFromTabletBreakpoint
} = MediaQuery;

const isDesktopDevice = deviceType => deviceType === 'desktop';

const isMobileOrTablet = () =>
  isMobileScreen() || isPhabletScreen() || isTabletScreen();

const isPoiFilled = poi => poi && poi.lat && poi.lng;

const pushDataLayerProductList = (markersPage, moveIn, moveOut) => {
  if (typeof dataLayer !== 'undefined') {
    const productIdList = markersPage.slice(0, 3).map(ru => ru.id);

    dataLayer.push({
      PageType: 'Listingpage',
      HashedEmail: '',
      ProductIDList: productIdList,
      din: moveIn,
      dout: moveOut
    });
  }
};

const NUMBER_OF_HOMECARDS_PER_PAGE = 60;

const getFirstPageSizeFromMarkers = (markers, defaultItemsPerPage) => {
  const soldOutPropertiesPresent = markers.filter(m => m.so);

  return soldOutPropertiesPresent.length > 0
    ? defaultItemsPerPage + soldOutPropertiesPresent.length
    : defaultItemsPerPage;
};

const SaveSearchButtonWrapper = ({ cityId, onClick }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const showButton = () => setShow(true);
    const hideButton = () => setShow(false);
    Mediator.subscribe(HOMECARD_MAP_OPEN, hideButton);
    Mediator.subscribe(HOMECARD_MAP_CLOSE, showButton);

    return () => {
      Mediator.unsubscribe(HOMECARD_MAP_OPEN, hideButton);
      Mediator.unsubscribe(HOMECARD_MAP_CLOSE, showButton);
    };
  }, []);

  return (
    show && (
      <div className="quick-alert-mobile__switcher">
        <div className="quick-alert-mobile__floating">
          <AlertButton
            buttonProps={{
              renderIcon: () => <Bell />,
              size: 'medium'
            }}
            cityId={cityId}
            onClick={onClick}
            dataTest="alert-button-mobile"
          />
        </div>
      </div>
    )
  );
};

class Search extends PureComponent {
  constructor(props) {
    super(props);
    const {
      filters,
      seoMode,
      sessionPoi,
      initialMarkerList,
      numberOfMarkers,
      itemsPerPage,
      map: loadMap
    } = props;

    const localFilters = {
      ...FilterUtils.getDefaultFilters(),
      ...filters,
      seoMode
    };

    let pointOfInterest = null;

    if (
      localFilters.sortBy === 'POI' &&
      localFilters.poiLat &&
      localFilters.poiLng
    ) {
      pointOfInterest = {
        lat: localFilters.poiLat,
        lng: localFilters.poiLng,
        name: localFilters.poiName || '',
        placeId: localFilters.poiId || ''
      };
      if (
        isPoiFilled(sessionPoi) &&
        pointOfInterest.lat === sessionPoi.lat &&
        pointOfInterest.lng === sessionPoi.lng
      ) {
        pointOfInterest = sessionPoi;
      }
    } else if (isPoiFilled(sessionPoi)) {
      pointOfInterest = sessionPoi;
    }

    localFilters.map = this.props.map;

    this.state = {
      ...INITIAL_STATE,
      paginatedMarkers: initialMarkerList,
      numberOfMarkers,
      pointOfInterest,
      isMapLoadRequested: loadMap,
      isMapVisible: false
    };

    this.cardPaginator = new CardPaginator({
      initialPage: filters.page,
      pageSize: itemsPerPage,
      firstPageSize: getFirstPageSizeFromMarkers(
        initialMarkerList,
        itemsPerPage
      )
    });

    this.cardPaginator.initializeData(this.state.paginatedMarkers);
  }

  componentDidMount() {
    const { pointOfInterest, paginatedMarkers } = this.state;
    const localFilters = this.props.filters;
    getFromTabletBreakpoint().addEventListener('change', this.showMap);

    this.showMap();

    this.cdManager = CustomDimensionsManager();

    if (isPoiFilled(pointOfInterest)) {
      this.updatePointOfInterestState(pointOfInterest);
    }

    if (paginatedMarkers.length > 0) {
      pushDataLayerProductList(
        paginatedMarkers,
        localFilters['move-in'],
        localFilters['move-out']
      );
    }

    if (!this.props.avoidMarkersRequest) {
      this.getMarkers();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Use prevProps, as prop prevFiltes will always be different
    const prevFilters = prevProps.filters;

    if (this.state.isFiltersHidden !== prevState.isFiltersHidden) {
      document.querySelector('body').style.overflow = this.state.isFiltersHidden
        ? 'auto'
        : 'hidden';
    }

    if (JSON.stringify(this.props.filters) !== JSON.stringify(prevFilters)) {
      let numberMarkers = this.state.numberOfMarkers;
      const { city } = this.props;

      const shouldUpdateMarkers = this.shouldUpdateMarkers(prevFilters);
      if (shouldUpdateMarkers) {
        numberMarkers = (await this.getMarkers()).numberMarkers;
        this.props.handleUpdatePage(this.cardPaginator.getCurrentPageNumber());
      }

      this.cdManager.setSearchQueryCustomDimensions(
        city.id,
        this.getAdaptedFilters(this.props.filters),
        numberMarkers
      );
    }

    if (prevFilters.map !== this.props.filters.map) {
      this.showMap();
    }

    if (this.props.map !== prevProps.map) {
      this.setState({ isMapLoadRequested: this.props.map });
    }
  }

  handlePlaceSelected = async place => {
    const cityId = this.props.city.id;
    if (!place) {
      await deletePoiRequest(cityId);
    } else {
      const { name, lat, lng, cityId: newPoiCityId } = place;

      if (name && lat && lng) {
        SoyuzAnalytics.sendGA4Event(PLACE_SELECTED_EVENT_CATEGORY, {
          place: name,
          coord: `${lat},${lng}`
        });
      }

      if (newPoiCityId && cityId !== newPoiCityId) {
        SoyuzAnalytics.sendGA4Event(
          PLACE_SELECTED_DIFFERENT_CITY_EVENT_CATEGORY,
          { currentCity: cityId, newPoiCityId }
        );
      }

      try {
        await setPoiRequest(cityId, place);
      } catch (error) {
        console.error(error);
      }
    }

    this.updatePointOfInterestState(place);
  };

  handleNextButtonClick = () => {
    if (this.cardPaginator.getNextPageNumber()) {
      this.cardPaginator.nextPage();
      this.onNewPageContent();
      this.scrollToTopOfHomecardList();
    }
  };

  handlePreviousButtonClick = () => {
    if (this.cardPaginator.getPreviousPageNumber()) {
      this.cardPaginator.previousPage();
      this.onNewPageContent();
      this.scrollToTopOfHomecardList();
    }
  };

  handleMapWillUnmount = () => {
    this.cardPaginator.restoreOriginalData();
    this.onNewPageContent();
  };

  handleMapDidMount = map => {
    this[MAP_ID] = map;
    this[MAP_ID].centerMap = () =>
      map.setCenter(this.props.locationCoordinates);
    this.boundariesFilter = elem =>
      map.coordsInBounds({
        lat: elem.coord[1],
        lng: elem.coord[0]
      });
  };

  handleToggleActiveView = () => {
    const toggled = !this.props.filters.map;
    this.props.handleUpdateMap(toggled || !isMobileOrTablet());
    this.setState(() => {
      this.sendViewMapListToggleEvent(toggled);

      return {
        isMapLoadRequested: toggled || !isMobileOrTablet()
      };
    });
  };

  handleSwitcherClick = () => {
    this.handleToggleActiveView();
    SoyuzAnalytics.sendGA4Event('Search', {
      action: 'MapListSwitch',
      value: 'click'
    });
  };

  handleSaveSearchMobile = () => {
    SoyuzAnalytics.sendGA4Event('Search', {
      category: 'Alert-primary-CTA',
      value: 'click'
    });
    SoyuzAnalytics.sendGA4Event('Search', {
      category: 'SaveMySearchMobile',
      value: 'click'
    });
  };

  handleOnFiltersToggleClick = () => {
    this.setState(({ isFiltersHidden }) => ({
      isFiltersHidden: !isFiltersHidden
    }));
  };

  handleSortByChange = async sortBy => {
    if (this.state.sortBy === 'POI') {
      await this.handlePlaceSelected(null);
    }
    this.handleFiltersUpdate({ sortBy });
  };

  handleRefreshMapBoundaries = () => {
    this.setState({ newMapBoundaries: false });
    this.cardPaginator.filter(this.boundariesFilter);
    this.onNewPageContent();
  };

  handleMapNewBounds = () => {
    this.setState({ newMapBoundaries: true });
    if (this.state.canUpdateBoundaries && !isMobileOrTablet()) {
      this.handleRefreshMapBoundaries();
    } else {
      this.setState({
        canUpdateBoundaries: true
      });
    }
  };

  handleMapZoomChange = bounds => {
    const { northEast, southWest } = bounds;
    const totalMarkersShown = this.cardPaginator.getLength();

    this.cdManager.setSearchMapCustomDimensions(
      `${northEast.lat}_${northEast.lng}`,
      `${southWest.lat}_${southWest.lng}`,
      totalMarkersShown
    );
    const label = `${northEast.lat},${northEast.lng},${southWest.lat},${southWest.lng};${totalMarkersShown}`;

    SoyuzAnalytics.sendGA4Event('send', {
      action: 'zoom-map',
      label
    });
  };

  handleFiltersUpdate = filtersToBeMerged => {
    const updatedFilters = Object.assign(
      {},
      this.props.filters,
      filtersToBeMerged
    );

    const newFilters = removeSeoFilters(updatedFilters);

    // remove rooms when user deselect apartments
    if (
      filtersToBeMerged['type[]'] &&
      !filtersToBeMerged['type[]'].includes('apartments')
    ) {
      newFilters['topology[]'] = [];
    }

    this.props.handleUpdateFilters(newFilters);
    this.triggerHotJarEventForLongStays(newFilters);
  };

  handleClearFilters = () => {
    if (isDesktopDevice(this.props.deviceType) && this[MAP_ID] !== undefined) {
      this[MAP_ID].centerMap();
    }
    this.handleFiltersUpdate(FilterUtils.getDefaultFilters());
  };

  handleCloseFilters = () => {
    if (!this.state.isFiltersHidden) {
      this.setState({
        isFiltersHidden: true
      });
      SoyuzAnalytics.sendGA4Event('Search', { action: 'closeFilters' });
    }
  };

  handleNoDepositNavigation = () => {
    this.handleFiltersUpdate({ noDeposit: 1 });
  };

  handleMapLazyLoad = () => {
    this.setState({ isMapLoadRequested: true });
    SoyuzAnalytics.sendGA4Event('Search', {
      action: 'MapPlaceholderCTA',
      value: 'click'
    });
  };

  onNewPageContent = () => {
    this.setState(this.getOnNewPageContentState);
    this.props.handleUpdatePage(this.cardPaginator.getCurrentPageNumber());
  };

  getOnNewPageContentState = () => ({
    paginatedMarkers: this.cardPaginator.getCurrentPageContent(),
    newMapBoundaries: false
  });

  getAdaptedFilters = filters =>
    FilterUtils.adaptFiltersFromUrl(filters, this.props.sortingAlgorithm);

  getMarkers = async () => {
    let newMarkers = [];
    let shiftedMarkers = [];
    let numberOfMarkers = this.props.numberOfMarkers;

    try {
      const { data } = await getMarkersQuery(getGqlClient())(
        this.props.city.id,
        this.getAdaptedFilters(this.props.filters)
      );

      newMarkers = data.search.markers;
      numberOfMarkers = newMarkers.length;

      shiftedMarkers = shiftMarkers(newMarkers);
      this.cardPaginator.updateData(shiftedMarkers);
      this.cardPaginator.setFirstPageSize(
        getFirstPageSizeFromMarkers(shiftedMarkers, this.props.itemsPerPage)
      );
      this.cardPaginator.filter(this.boundariesFilter);

      this.setState(({ maxTotalResults }) => ({
        maxTotalResults: Math.max(maxTotalResults, numberOfMarkers),
        numberOfMarkers,
        hasInstantBooking: shiftedMarkers.some(marker => marker.instantBooking),
        ...this.getOnNewPageContentState()
      }));

      return {
        numberMarkers: newMarkers.length
      };
    } catch (error) {
      if (error.message !== 'CANCELED') {
        Metrics.hotjar.recordTag('ErrorGetMarkersSearch');
        clientErrorTracker.error({ error, source: 'getMarkersSearch-Error' });
      }
    }

    return {
      numberMarkers: this.state.numberOfMarkers
    };
  };

  getCurrentPage = () => this.cardPaginator.getCurrentPageNumber();

  showMap = () => {
    const isServer = typeof window === 'undefined';
    const isManuallyEnabled = this.props.filters.map;
    const isDesktop = isServer
      ? isDesktopDevice(this.props.deviceType)
      : isBiggerThanTablet();

    if (this.state.isMapVisible !== isManuallyEnabled || isDesktop) {
      this.setState(prevState => ({
        ...prevState,
        isMapVisible: isManuallyEnabled || isDesktop
      }));
    }
  };

  sendViewMapListToggleEvent = isMapVisible => {
    const label = isMapVisible
      ? VIEW_MAP_LIST_EVENT_VIEW_MAP_TAG
      : VIEW_MAP_LIST_EVENT_VIEW_LIST_TAG;
    SoyuzAnalytics.sendGA4Event(VIEW_MAP_LIST_EVENT_CATEGORY, {
      category: VIEW_MAP_LIST_EVENT_ACTION,
      value: label
    });
  };

  updatePointOfInterestState = point => {
    let pointOfInterest = null;
    let sortBy;
    let poi = {
      poiLat: '',
      poiLng: '',
      poiName: '',
      poiId: '',
      sortBy
    };

    if (point) {
      pointOfInterest = point;
      sortBy = 'POI';
      poi = {
        poiLat: point.lat,
        poiLng: point.lng,
        poiName: point.name,
        poiId: point.placeId,
        sortBy
      };
    }

    this.handleFiltersUpdate(poi);
    this.setState({ pointOfInterest, sortBy });
  };

  isPrevButtonDisabled = () =>
    this.cardPaginator.getPreviousPageNumber() === null;

  isNextButtonDisabled = () => this.cardPaginator.getNextPageNumber() === null;

  shouldUpdateMarkers = prevFilters => {
    const currentFilters = this.props.filters;
    const filterKeys = Object.keys(currentFilters).filter(
      key => !['page', 'map'].includes(key)
    );

    return filterKeys.some(key => currentFilters[key] !== prevFilters[key]);
  };

  triggerHotJarEventForLongStays = filters => {
    const {
      'move-in': moveIn,
      'move-out': moveOut,
      'rentalType[]': rentalType
    } = filters;

    const { currentLocale } = this.props;

    const currentCityIsInSpain = cityToggleConfig(this.props.city.id).isInSpain;
    const hasLongTermRentalType = rentalType && rentalType.includes('longTerm');
    const hasLongStayInDates =
      moveIn && moveOut && differenceInMonths(moveOut, moveIn) >= 9;

    if (currentCityIsInSpain && (hasLongTermRentalType || hasLongStayInDates)) {
      Metrics.hotjar.triggerEvent(
        `${HOTJAR_POTENTIAL_PERMANENT_BOOKING}_${currentLocale}`
      );
    }
  };

  scrollToTopOfHomecardList = () =>
    document.querySelector('.search-listing').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });

  shouldRenderSeoContent = () =>
    this.props.seoMode &&
    !!this.props.seoContent &&
    !!this.props.seoContent.body;

  renderMapView = () => {
    return this.state.isMapVisible ? (
      <section className="search-map">
        <NoSSR>{this.renderMapOverlay()}</NoSSR>
        {this.renderMap()}
      </section>
    ) : null;
  };

  renderMapListSwitcher = isPositionRight => (
    <MapListSwitcher
      onClickLeft={this.handleSwitcherClick}
      onClickRight={this.handleSwitcherClick}
      isPositionRight={isPositionRight}
    />
  );

  renderMapOverlay = (showSwitcher = false) => {
    const isMobile = isMobileOrTablet();
    const showAutocompleter = isMobile || this.state.isMapLoadRequested;

    return (
      showAutocompleter && (
        <div className="search-map__overlay" data-test="map-overlay">
          <div className={`${isMobile ? 'search-map__filters' : ''}`}>
            <div
              className={classNames('search-map__places--extra-content', {
                'search-map__places': isMobile
              })}
            >
              <Suspense fallback={null}>
                <PlacesAutocomplete
                  flat={isMobile}
                  boundCoords={this.props.locationCoordinates}
                  pointOfInterest={this.state.pointOfInterest}
                  onPlaceSelected={this.handlePlaceSelected}
                  countryCode={this.props.countryCode}
                  renderExtraContent={() =>
                    isMobile ? this.renderMapListSwitcher(true) : null
                  }
                />
              </Suspense>
            </div>
            {isMobile && this.renderExposedFilters()}
          </div>

          {isMobile && (
            <FabButton
              className={classNames('search-map__refresh-button-rebranding', {
                'search-map__refresh-button-rebranding--hidden':
                  !this.state.newMapBoundaries
              })}
              color="secondary-alt"
              onClick={this.handleRefreshMapBoundaries}
              dataTest="searchHereButton"
              renderContent={() => trans('search.search-here')}
              renderIcon={() => <RefreshIcon />}
            />
          )}

          {(isMobile || showSwitcher) && (
            <HomecardMap
              data-cy="propertyCard"
              cityId={this.props.city.id}
              deviceType={this.props.deviceType}
            />
          )}
        </div>
      )
    );
  };

  renderMap = () => {
    const {
      currency,
      clientConfig: { defaultZoom },
      city,
      locationCoordinates
    } = this.props;
    const showMap = this.state.isMapLoadRequested;

    if (!locationCoordinates) {
      return null;
    }

    return showMap ? (
      <Suspense fallback={null}>
        <Map
          ref={map => (this[MAP_ID] = map)}
          mapId={MAP_ID}
          currencyIsoCode={currency.isoCode}
          centerCoordinates={locationCoordinates}
          defaultZoom={defaultZoom[city.id]}
          markerList={this.state.paginatedMarkers}
          pointOfInterest={this.state.pointOfInterest}
          onMarkerClicked={() => this.setState({ isFiltersHidden: true })}
          onMapDidMount={this.handleMapDidMount}
          onMapWillUnmount={this.handleMapWillUnmount}
          onIdle={this.handleMapNewBounds}
          onZoomChange={this.handleMapZoomChange}
          showPrice
          isMapLoadDelayed
        />
      </Suspense>
    ) : (
      <MapPlaceholder
        city={city.id}
        cityCenterCoords={locationCoordinates}
        onButtonClick={() => {
          this.handleMapLazyLoad();
        }}
      />
    );
  };

  renderPaginator = () => {
    const { locationString } = this.props;
    const { filters } = this.props;
    const nextIsDisabled = this.isNextButtonDisabled();
    const previousIsDisabled = this.isPrevButtonDisabled();

    const nextPage = AdUrlService.getSearchUrlFromFilters(locationString, {
      ...filters,
      page: this.cardPaginator.getNextPageNumber()
    });

    const nextPageUrl = nextIsDisabled ? '' : nextPage;

    const previousPage = AdUrlService.getSearchUrlFromFilters(locationString, {
      ...filters,
      page: this.cardPaginator.getPreviousPageNumber()
    });

    const previousPageUrl = previousIsDisabled ? '' : previousPage;

    return (
      this.cardPaginator.getTotalPages() > 1 && (
        <Paginator
          previousButtonDisabled={previousIsDisabled}
          nextButtonDisabled={nextIsDisabled}
          onPreviousButtonClick={this.handlePreviousButtonClick}
          onNextButtonClick={this.handleNextButtonClick}
          nextPageUrl={nextPageUrl}
          previousPageUrl={previousPageUrl}
        />
      )
    );
  };

  renderHomecardList = () => {
    const { seoContent, internalLinking, seoSnippet } = this.props;
    const hasZeroResults = this.cardPaginator.getOriginalLength() === 0;
    const markerList = this.state.paginatedMarkers;

    if (markerList.length === 0) {
      SoyuzAnalytics.sendGA4Event('Search', {
        action: 'no-results'
      });
    }

    if (markerList.length < NUMBER_OF_HOMECARDS_PER_PAGE) {
      SoyuzAnalytics.sendGA4Event('Search', {
        action: 'incomplete-page',
        value: markerList.length
      });
    }

    const numberOfSoldoutHomecards = markerList.filter(hc => hc.so).length;

    if (numberOfSoldoutHomecards > 0) {
      SoyuzAnalytics.sendGA4Event('Search', {
        section: 'soldout-homecards',
        value: numberOfSoldoutHomecards,
        number_of_items: markerList.length
      });
    }

    return (
      <div className="search-listing">
        <div className="search-listing__total-results">
          <SearchBanner cityId={this.props.city.id} />
          <SearchTitle
            cityName={this.props.city.cityName}
            totalResults={this.state.numberOfMarkers}
            onSortChange={this.handleSortByChange}
            onClearFilters={this.handleClearFilters}
          />
        </div>
        {hasZeroResults ? (
          <RemoveFilters onFiltersUpdate={this.handleFiltersUpdate} />
        ) : null}
        <HomeCardList
          initialHomecards={this.props.initialHomecards}
          markerList={this.state.paginatedMarkers}
          cityId={this.props.city.id}
          showAlertCreationReminder={this.isNextButtonDisabled()}
          preloadedHomecards={this.props.preloadedHomecards}
          hasLazyLoad
          deviceType={this.props.deviceType}
        />

        {this.renderPaginator()}
        <SearchBreadcrumbs
          cityId={this.props.city.id}
          cityName={this.props.city.cityName}
          countryId={this.props.city.country.countryId}
          propertyType={this.props.filters['type[]']}
          topology={this.props.filters['topology[]']}
        />

        <InternalLinkingModules internalLinking={internalLinking} />

        {this.shouldRenderSeoContent() && (
          <SEOContent
            title={seoContent.title}
            subtitle={seoContent.subtitle}
            body={seoContent.body}
          />
        )}

        <SEOSnippet seoSnippet={seoSnippet} />
      </div>
    );
  };

  renderExposedFilters = () => {
    const totalResults = this.cardPaginator.getOriginalLength();
    const { currency, maxPriceInCity, city, sortingAlgorithm } = this.props;

    return (
      <FiltersExposed
        totalResults={totalResults}
        currencyIsoCode={currency.isoCode}
        onFiltersClose={this.handleCloseFilters}
        onFiltersToggle={this.handleOnFiltersToggleClick}
        onFiltersUpdate={this.handleFiltersUpdate}
        maxPriceInCity={maxPriceInCity}
        minimumStaying={city.minimumStaying}
        cityId={city.id}
        sortingAlgorithm={sortingAlgorithm}
      />
    );
  };

  renderFullFilters = () => {
    return !this.state.isFiltersHidden ? (
      <NoSSR>
        <div className="search__filters-full">
          <Filters
            currency={this.props.currency}
            hasInstantBooking={this.state.hasInstantBooking}
            maxPriceInCity={this.props.maxPriceInCity}
            onFiltersUpdate={this.handleFiltersUpdate}
            cityId={this.props.city.id}
            onClearFilters={this.handleClearFilters}
            onCloseFilters={this.handleCloseFilters}
            totalResults={this.cardPaginator.getOriginalLength()}
          />
        </div>
        <FiltersHeader
          results={this.state.numberOfMarkers}
          citySlug={this.props.city.id}
          totalResults={this.cardPaginator.getOriginalLength()}
          onHandleFiltersToggle={this.handleOnFiltersToggleClick}
          maxTotalResults={this.state.maxTotalResults}
          currency={this.props.currency}
          isFooterInSearchPage
        />
      </NoSSR>
    ) : null;
  };

  renderBanners = () => {
    return <ValueProposition className="search-info-banner" />;
  };

  render() {
    const { city, isSafari } = this.props;

    const { map } = this.props.filters;

    const isMobileOrTabletOnMapView = map && isMobileOrTablet();

    const renderMapListSwitcher = () =>
      this.renderMapListSwitcher(isMobileOrTabletOnMapView);

    const headerContainerClass = classNames('wrapper-header', {
      'wrapper-header--hidden': isMobileOrTabletOnMapView
    });

    return (
      <>
        {!isMobileOrTabletOnMapView && (
          <div className={headerContainerClass}>
            <HeaderContainer renderExtraContent={renderMapListSwitcher} />
            <NoSSR>{this.renderExposedFilters()}</NoSSR>
          </div>
        )}

        <div id="wrapper-search" data-browser={isSafari ? 'safari' : 'other'}>
          <div className="layout-search">
            <div className="search-results">
              <div className="search__sidebar">
                {this.renderFullFilters()}
                <div id="search-scroll" className="search__scroll">
                  <PreFilters onFiltersUpdate={this.handleFiltersUpdate} />
                  {this.renderBanners()}
                  {this.renderHomecardList()}
                </div>
              </div>

              <SaveSearchButtonWrapper
                cityId={this.props.city.id}
                onClick={this.handleSaveSearchMobile}
              />

              {this.renderMapView()}
              {isMobileOrTabletOnMapView && <>{this.renderFullFilters()}</>}
            </div>
            <FooterSimplified />
          </div>
        </div>

        <NoSSR>
          <AppliedFiltersModal cityId={city.id} />
          <AlertInPastModal />
        </NoSSR>
      </>
    );
  }
}

Search.propTypes = {
  locationString: PropTypes.string,
  locationCoordinates: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string
  }).isRequired,
  sessionPoi: PropTypes.shape(poiShape),
  city: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cityName: PropTypes.string.isRequired,
    minimumStaying: PropTypes.number.isRequired,
    center: PropTypes.shape(mapPointShape),
    country: PropTypes.shape({
      countryId: PropTypes.string
    })
  }).isRequired,
  clientConfig: PropTypes.shape({
    defaultZoom: PropTypes.shape({
      [PropTypes.string]: PropTypes.number
    })
  }).isRequired,
  countryCode: PropTypes.string,
  currency: PropTypes.shape({ isoCode: PropTypes.string }),
  maxPriceInCity: PropTypes.number.isRequired,
  filters: PropTypes.shape({
    'move-in': PropTypes.string,
    'move-out': PropTypes.string,
    map: PropTypes.bool,
    'type[]': PropTypes.arrayOf(PropTypes.string),
    'topology[]': PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.number
  }),
  handleUpdateFilters: PropTypes.func.isRequired,
  initialMarkerList: PropTypes.arrayOf(PropTypes.object),
  numberOfMarkers: PropTypes.number.isRequired,
  sortingAlgorithm: PropTypes.string,
  itemsPerPage: PropTypes.number.isRequired,
  initialHomecards: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      isInstantBooking: PropTypes.bool
    })
  }).isRequired,
  seoMode: PropTypes.bool,
  seoContent: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    body: PropTypes.string
  }),
  currentLocale: PropTypes.string.isRequired,
  map: PropTypes.bool,
  avoidMarkersRequest: PropTypes.bool,
  preloadedHomecards: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSafari: PropTypes.bool.isRequired,
  internalLinking: PropTypes.arrayOf(PropTypes.shape(internalLinkingModule))
    .isRequired,
  seoSnippet: PropTypes.arrayOf(PropTypes.shape(SeoSnippetShape)).isRequired,
  deviceType: PropTypes.string.isRequired,
  handleUpdatePage: PropTypes.func.isRequired,
  handleUpdateMap: PropTypes.func.isRequired
};

Search.defaultProps = {
  locationString: '',
  sessionPoi: {},
  filters: {},
  sortingAlgorithm: '',
  initialMarkerList: [],
  countryCode: '',
  currency: undefined,
  seoMode: false,
  seoContent: null,
  map: false,
  avoidMarkersRequest: false
};

export default Search;

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trans } from '@spotahome/soyuz/client';

import ThemedPrice, * as ThemedPriceDef from '../../ThemedPrice';

import styles from './Price.module.scss';

const Price = props => {
  const i18n = {
    from: trans('rentable_unit_price.label.from'),
    perMonth: trans('price_tag.label.per_month')
  };

  const theme = {
    currency: styles.currency,
    price: classNames(styles.price, {
      [styles['price--bigger-font']]: props.biggerFont
    }),
    priceMonthly: classNames(
      styles['price-monthly'],
      styles['price-monthly--rebranding-style'],
      {
        [styles['price-monthly--bigger-font']]: props.biggerFontMonthly
      }
    ),
    wrapper: classNames(
      styles.wrapper,
      styles[`wrapper--${props.color}`],
      {
        [styles['wrapper--inline']]: props.inline
      },
      props.className
    )
  };

  return <ThemedPrice {...props} i18n={i18n} theme={theme} />;
};

Price.defaultProps = {
  ...ThemedPriceDef.defaultProps,
  color: 'default'
};

Price.propTypes = {
  ...ThemedPriceDef.propTypes,
  color: PropTypes.oneOf(['default', 'light-black', 'interaction', 'dark'])
};

export default Price;

import PropTypes from 'prop-types';

const SahContainer = ({ children }) => <div>{children}</div>;

SahContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default SahContainer;

import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useInView } from 'react-intersection-observer';

import { trans, useSoyuzExperiment } from '@spotahome/soyuz/client';

import classNames from 'classnames/bind';

import Price from '../../common/Price';
import Button from '../../../Button';

import LabelItem from '../../../LabelItem';
import Tag from '../../../Tag';
import BaseChip from '../../../BaseChip';

import IconGeneric from '../../../IconGeneric';

import {
  ROOM_SHARED,
  ROOM_SHARED_APT,
  LISTING_TYPES,
  RESIDENCE
} from '../../../../data/common/property';

import styles from './HomecardContent.module.scss';

const DAY_FORMAT = 'D';
const DEFAULT_LISTING_TYPE = ROOM_SHARED;
const TRANS_DELIMITER = '_';

const TRANSLATION_TYPE_MAPPING = {
  [ROOM_SHARED_APT]: ROOM_SHARED
};

const getTranslatedMonth = month => {
  const translatedMonths = trans('pikaday.label.months').split(TRANS_DELIMITER);

  return translatedMonths[month];
};

const getTranslatedType = type => {
  const mappedType = TRANSLATION_TYPE_MAPPING[type] || type;
  return trans(`homecard.type.${mappedType}.small`);
};

const parseAvailableFrom = availableFrom => {
  const date = moment(availableFrom);
  const month = date.month();
  const translatedMonth = getTranslatedMonth(month);

  const year = date.year();

  return {
    day: date.format(DAY_FORMAT),
    month: translatedMonth,
    year
  };
};

const isRoomsSquaredMetersType = type =>
  type === ROOM_SHARED || type === ROOM_SHARED_APT || type === RESIDENCE;

const HomecardContentDescription = ({
  position = 'default',
  onMoreInfoClick
}) => (
  <div className={styles[`homecard-content__info--${position}`]}>
    <Button color="link" onClick={onMoreInfoClick}>
      {trans('homecard.more.info')}
    </Button>
  </div>
);

const HomecardContent = ({
  availableFrom,
  title,
  link,
  target,
  onClick,
  price = {},
  type = DEFAULT_LISTING_TYPE,
  labels = [],
  chips = [],
  numberOfBathrooms = 0,
  numberOfBedrooms = 0,
  showAvailableFromYear = false,
  withTransparentBackground = false,
  onMoreInfoClick = () => {},
  hasDescription = false,
  descriptionPosition = 'default',
  offerDiscount = null,
  isBooked = false,
  area = null,
  roomArea = null,
  showSquaredMeters = false,
  isEmbedded = false
}) => {
  const { day, month, year } = parseAvailableFrom(availableFrom);
  const hasLabels = labels.some(label => label.text && label.color);
  const hasChips = chips.length;
  const hasBathrooms = !!numberOfBathrooms;
  const hasBedrooms = !!numberOfBedrooms;

  const showDefaultDescription =
    hasDescription && descriptionPosition === 'default' && !isBooked;

  const showDescriptionAtBottom =
    hasDescription && descriptionPosition === 'bottom' && !isBooked;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const isSquaredMetersExp = useSoyuzExperiment('squaredMetersHomecard');

  const squaredMetersRoomUnit =
    showSquaredMeters && roomArea > 0 && isRoomsSquaredMetersType(type);
  const showRoomSquaredMeters =
    squaredMetersRoomUnit && isSquaredMetersExp.get('behaviour') === 'variant';
  const squaredMetersTotalUnit =
    showSquaredMeters && area > 0 && !isRoomsSquaredMetersType(type);
  const showTotalSquaredMeters =
    squaredMetersTotalUnit && isSquaredMetersExp.get('behaviour') === 'variant';

  useEffect(() => {
    if (inView && (squaredMetersRoomUnit || squaredMetersTotalUnit)) {
      SoyuzAnalytics.sendGA4Event('show', { section: 'homecard-meters' });
    }
  }, [inView]);

  const Component = isBooked ? 'span' : 'a';
  const componentProps = isBooked
    ? {}
    : {
        href: link,
        target,
        onClick
      };

  return (
    <div
      className={classNames(styles['homecard-content'], {
        [styles['homecard-content--with-transparent-background']]:
          withTransparentBackground
      })}
      ref={ref}
    >
      <Component
        className={styles['homecard-content__header']}
        {...componentProps}
      >
        {isBooked ? (
          <span className={styles['homecard-content__booked']}>
            {trans('homecard.is-booked.description')}
          </span>
        ) : (
          <div>
            <span
              className={classNames(styles['homecard-content__type'], {
                [styles['homecard-content__type--variant']]: isEmbedded
              })}
            >
              {getTranslatedType(type)}
            </span>

            {(showRoomSquaredMeters || showTotalSquaredMeters) && (
              <>
                <span className={styles['homecard-content__separator']}>
                  &#9632;
                </span>

                <span className={styles['homecard-content__squared-meters']}>
                  {showRoomSquaredMeters ? roomArea : area} m²
                </span>
              </>
            )}

            <span className={styles['homecard-content__separator']}>
              &#9632;
            </span>

            <span
              className={classNames(
                styles['homecard-content__available-from'],
                {
                  [styles['homecard-content__available-from--variant']]: isEmbedded
                }
              )}
              dangerouslySetInnerHTML={{
                __html: trans('homecard.available-from.rebranding', {
                  ordinalDate: showAvailableFromYear
                    ? `${day} ${month} ${year}`
                    : `${day} ${month}`
                })
              }}
            />
          </div>
        )}

        <p
          className={classNames(styles['homecard-content__title'], {
            [styles['homecard-content__title--variant']]: isEmbedded
          })}
        >
          {title}
        </p>

        {(hasBathrooms || hasBedrooms) && (
          <div className={styles['homecard-content__rooms']}>
            {hasBedrooms && (
              <span>
                <IconGeneric
                  name="single-bed"
                  title={trans('listing.property-title.bedrooms')}
                />
                x{numberOfBedrooms}
              </span>
            )}
            {hasBathrooms && hasBedrooms && (
              <span className={styles['homecard-content__separator']}>
                &#9632;
              </span>
            )}
            {hasBathrooms && (
              <span>
                <IconGeneric
                  name="bathroom"
                  title={trans('listing.property-title.bathrooms')}
                />
                x{numberOfBathrooms}
              </span>
            )}
          </div>
        )}
      </Component>

      <div className={styles['homecard-content__footer']}>
        <div className={styles['homecard-content__price-container']}>
          <Price
            {...price}
            color="dark"
            className={styles['homecard-content__price']}
            biggerFont={isEmbedded}
            biggerFontMonthly={isEmbedded}
          />
          {offerDiscount && (
            <Tag
              className={styles['homecard-content__price-tag']}
              colorSchema="pink"
              label={trans('homecard.label.up_to_discount', {
                discount: offerDiscount
              }).toUpperCase()}
            />
          )}
        </div>
        {showDefaultDescription && (
          <HomecardContentDescription onMoreInfoClick={onMoreInfoClick} />
        )}
        {hasLabels ? (
          <div className={styles['homecard-content__label-container']}>
            {labels.map(label => (
              <LabelItem
                key={label.text}
                size={label.size || 'xxs'}
                {...label}
              />
            ))}
          </div>
        ) : null}
        {!hasLabels && hasChips ? (
          <div className={styles['homecard-content__label-container']}>
            {chips.map(chip => (
              <Tag
                colorSchema={chip.colorSchema || 'tag'}
                key={chip.label}
                {...chip}
              />
            ))}
          </div>
        ) : null}
        {showDescriptionAtBottom && (
          <HomecardContentDescription
            position="bottom"
            onMoreInfoClick={onMoreInfoClick}
          />
        )}
      </div>
    </div>
  );
};

HomecardContent.propTypes = {
  availableFrom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  price: PropTypes.shape(Price.propTypes),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(LISTING_TYPES),
  labels: PropTypes.arrayOf(PropTypes.shape(LabelItem.propTypes)),
  chips: PropTypes.arrayOf(PropTypes.shape(BaseChip.propTypes)),
  numberOfBathrooms: PropTypes.number,
  numberOfBedrooms: PropTypes.number,
  showAvailableFromYear: PropTypes.bool,
  withTransparentBackground: PropTypes.bool,
  onMoreInfoClick: PropTypes.func,
  hasDescription: PropTypes.bool,
  descriptionPosition: PropTypes.oneOf(['bottom', 'default']),
  offerDiscount: PropTypes.string,
  link: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isBooked: PropTypes.bool,
  area: PropTypes.number,
  roomArea: PropTypes.number,
  showSquaredMeters: PropTypes.bool,
  isEmbedded: PropTypes.bool
};

export default HomecardContent;

import { lazy, Suspense } from 'react';

import Spinner from '../Spinner/Spinner';

const ModalOptIn = lazy(() => import('./ModalOptIn'));

const LazyModalOptIn = props => (
  <Suspense fallback={<Spinner small />}>
    <ModalOptIn {...props} />
  </Suspense>
);

export default LazyModalOptIn;

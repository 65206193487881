import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trans, useSoyuzExperiment } from '@spotahome/soyuz/client';

import Button from '../../../Button';
import DSIcon from '../../../../../base/DSIcon';

import FeatureService from '../../../../services/FeatureService';

import {
  APARTMENT,
  STUDIO,
  ROOM_SHARED,
  ROOM_SHARED_APT,
  RESIDENCE,
  LISTING_TYPES,
  isApartment,
  isRoom
} from '../../../../data/common/property';

import styles from './HomecardDescription.module.scss';

import {
  STUDIO_AND_APARTMENT_FEATURES,
  ROOM_SHARED_AND_RESIDENCE_FEATURES
} from './features';

const FEATURES_LIMIT = 8;

export const renderListingTypeFeaturesIcons = (type, listingFeatures) => {
  const features = [APARTMENT, STUDIO].includes(type)
    ? STUDIO_AND_APARTMENT_FEATURES
    : ROOM_SHARED_AND_RESIDENCE_FEATURES;

  const featuresToShow = {};

  features.forEach((featureId, i) => {
    if (
      FeatureService.isFeatureAvailableToShow(
        featureId,
        listingFeatures?.[featureId] ?? false
      )
    ) {
      featuresToShow[featureId] = {
        value: listingFeatures?.[featureId],
        order: i
      };
    }
  });

  const formattedFeatures = FeatureService.getFeaturesAsList(featuresToShow);
  const availableFeatures =
    FeatureService.getAvailableFeaturesList(formattedFeatures);

  return availableFeatures
    .slice(0, FEATURES_LIMIT)
    .sort((a, b) => a.order - b.order)
    .map(feature => (
      <DSIcon
        key={feature.id}
        name={FeatureService.getIconFromFeatureId(feature.id)}
        title={FeatureService.getNameFromFeatureId(feature.id, feature.value)}
      />
    ));
};

const isRoomsSquaredMetersType = type =>
  type === ROOM_SHARED || type === ROOM_SHARED_APT || type === RESIDENCE;

const HomecardDescription = ({
  type = ROOM_SHARED,
  link = '',
  linkTarget = '',
  description = null,
  onCloseClick
}) => {
  const { area, roomArea, numberOfBathrooms, features, content } = description;

  const isSquaredMetersExp = useSoyuzExperiment('squaredMetersHomecard');

  const showRoomSquaredMeters =
    roomArea > 0 &&
    isRoomsSquaredMetersType(type) &&
    isSquaredMetersExp.get('behaviour') === 'variant';

  const showArea = !isRoom(type) && area > 0;

  return (
    <div className={styles['homecard-description']}>
      <div className={styles['homecard-description__content']}>
        {showArea || showRoomSquaredMeters ? (
          <div>
            <strong className={styles['homecard-description__content__label']}>
              {trans('homecard.description.size')}:
            </strong>{' '}
            {showRoomSquaredMeters ? roomArea : area}m²
          </div>
        ) : null}
        {isApartment(type) && (
          <div>
            <strong className={styles['homecard-description__content__label']}>
              {trans('homecard.description.bathrooms')}:
            </strong>{' '}
            {numberOfBathrooms}
          </div>
        )}
        <div>
          <strong>{trans('homecard.description.amenities')}:</strong>
        </div>
        <div className={styles['homecard-description__icons']}>
          {renderListingTypeFeaturesIcons(type, features)}
        </div>
        <hr className={styles['homecard-description__content-separator']} />
        <div
          className={classNames(styles['homecard-description__description'], {
            [styles['homecard-description__description--reduced']]:
              isApartment(type)
          })}
        >
          {content}
        </div>
      </div>
      <hr className={styles['homecard-description__footer-separator']} />
      <div className={styles['homecard-description__footer']}>
        <Button color="link" onClick={onCloseClick}>
          {trans('homecard.description.close')}
        </Button>
        <Button href={link} target={linkTarget} color="secondary" size="small">
          {trans('homecard.description.view-more')}
        </Button>
      </div>
    </div>
  );
};

HomecardDescription.propTypes = {
  type: PropTypes.oneOf(LISTING_TYPES),
  link: PropTypes.string,
  linkTarget: PropTypes.string,
  description: PropTypes.shape({
    area: PropTypes.number,
    numberOfBathrooms: PropTypes.number,
    features: PropTypes.shape({
      [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
      ])
    }).isRequired,
    content: PropTypes.string
  }),
  onCloseClick: PropTypes.func.isRequired
};

export default HomecardDescription;

export const TOKEN_EXPIRED = 'token_expired';
export const OPEN_MENU = 'open_menu';
export const OPEN_ALERT_MODAL = 'open_alert_modal';
export const OPEN_PHOTO_SLIDER = 'open_image_slider';
export const OPEN_APPLIED_FILTERS_MODAL = 'open_applied_filters_modal';
export const OPEN_OUT_OF_DATE_ALERT_MODAL = 'open_out_of_date_alert_modal';
export const COMMON_LOGIN_MODAL = 'AUTH_MODAL_OPEN';
export const EXTERNAL_REDIRECTION_LOGIN_MODAL =
  'EXTERNAL_REDIRECTION_LOGIN_MODAL';
export const OPEN_BOOKNOW_DATEPICKER_DESKTOP =
  'open_booknow_datepicker_desktop';
export const OPEN_BOOKNOW_DATEPICKER_MOBILE = 'open_booknow_datepicker_mobile';
export const NAVIGATE_CHECKOUT_FUNNEL = 'navigate_checkout_funnel';
export const SHOW_EXTENDED_LANDLORD_INFORMATION =
  'SHOW_EXTENDED_LANDLORD_INFORMATION';

export const OPEN_BILLS_MODAL = 'open_bills_modal';
export const HOMECARD_MAP_OPEN = 'HOMECARD_MAP_OPEN';
export const HOMECARD_MAP_CLOSE = 'HOMECARD_MAP_CLOSE';

export default {
  TOKEN_EXPIRED,
  OPEN_MENU,
  OPEN_ALERT_MODAL,
  OPEN_PHOTO_SLIDER,
  OPEN_APPLIED_FILTERS_MODAL,
  COMMON_LOGIN_MODAL,
  EXTERNAL_REDIRECTION_LOGIN_MODAL,
  OPEN_BOOKNOW_DATEPICKER_DESKTOP,
  OPEN_BOOKNOW_DATEPICKER_MOBILE,
  NAVIGATE_CHECKOUT_FUNNEL,
  OPEN_BILLS_MODAL,
  SHOW_EXTENDED_LANDLORD_INFORMATION,
  OPEN_OUT_OF_DATE_ALERT_MODAL,
  HOMECARD_MAP_OPEN,
  HOMECARD_MAP_CLOSE
};

import HomecardImage from '../common/HomecardImage';

import HomecardContent from './components/HomecardContent';
import HomecardDescription from './components/HomecardDescription';
import HomecardWrapper from './components/HomecardWrapper';

export { HOMECARD_IMAGE_RATIOS } from '../common/HomecardImage';

const Homecard = props => (
  <HomecardWrapper
    {...props}
    renderImage={componentProps => <HomecardImage {...componentProps} />}
    renderContent={componentProps => <HomecardContent {...componentProps} />}
    renderDescription={componentProps => (
      <HomecardDescription {...componentProps} />
    )}
  />
);

export default Homecard;

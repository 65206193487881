import { useEffect } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Mediator } from '@spotahome/soyuz-mediator';
import { trans } from '@spotahome/soyuz/client';
import { Button, useUserInfoContext } from '@spotahome/ui-library';
import { setItem } from '@spotahome/ui-library/src/utils/localStorage';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import {
  QUICK_ALERT_STORAGE_DATA,
  createAlert,
  getAlertFromFilters,
  getAlertDataFromStorage
} from '../utils/alerts';

import { COMMON_LOGIN_MODAL } from '../channels';

const triggerOpenModal = () => {
  Mediator.publish(COMMON_LOGIN_MODAL, {
    initialAction: 'signup',
    renderTitle: action => trans(`${action}.modal.title`),
    renderSubtitle: action => trans(`${action}.modal.subtitle`),
    events: {
      open: {
        eventCategory: 'signup/login modal',
        eventAction: 'Alerts',
        eventLabel: 'open'
      },
      close: {
        eventCategory: 'signup/login modal',
        eventAction: 'Alerts',
        eventLabel: 'close'
      }
    }
  });
};

const AlertButton = ({
  expandedFilters = false,
  cityId,
  onClick = () => {},
  buttonClassName = '',
  buttonProps = {},
  customText = '',
  dataTest = 'alert-button'
}) => {
  const { filters } = useFiltersContext();
  const { isUserLogged } = useUserInfoContext();

  useEffect(() => {
    const alertData = getAlertDataFromStorage(QUICK_ALERT_STORAGE_DATA);

    if (isUserLogged && alertData) {
      createAlert(alertData);
    }
  }, [isUserLogged]);

  const handleAlertCreation = () => {
    const quickAlertData = {
      ...getAlertFromFilters(cityId, filters),
      mediums: ['email']
    };

    if (isUserLogged) {
      createAlert(quickAlertData);
    } else {
      // eslint-disable-next-line import/no-named-as-default-member
      setItem(QUICK_ALERT_STORAGE_DATA, JSON.stringify(quickAlertData));
      triggerOpenModal();
    }
    onClick();
  };

  const className = classNames(
    'filter-header__results-button',
    'ga-search-form-my-alerts',
    {
      'filter-header__results-button--filters-collapsed': !expandedFilters
    },
    buttonClassName
  );

  return (
    <Button
      data-test={dataTest}
      onClick={handleAlertCreation}
      className={className}
      {...buttonProps}
    >
      {customText || trans('search.alert.button.save-my-search')}
    </Button>
  );
};

AlertButton.propTypes = {
  cityId: PropTypes.string.isRequired,
  expandedFilters: PropTypes.bool,
  onClick: PropTypes.func,
  buttonClassName: PropTypes.string,
  buttonProps: PropTypes.shape({}),
  customText: PropTypes.string,
  dataTest: PropTypes.string
};

export default AlertButton;

/* global dataLayer */
import { Suspense, lazy, useState, useEffect } from 'react';

import { useSoyuzData, trans } from '@spotahome/soyuz/client';
import { Mediator } from '@spotahome/soyuz-mediator';
import {
  ToastContainer,
  ThemeProvider,
  CookiesProvider,
  UserInfoProvider,
  HotspotsProvider,
  TenantTypeProvider,
  DeviceProvider,
  GoogleOneTap,
  AppBanner,
  CitiesProvider,
  SpottyCat
} from '@spotahome/ui-library';
import JsonLdHomecardListCarousel from '@spotahome/ui-library/src/tenant/components/JsonLdHomecardListCarousel';
import { useCoreWebVitals } from '@spotahome/ui-library/src/hooks/useCoreWebVitals';

import { useHistory } from 'react-router-dom';
import Metrics from '@spotahome/soyuz-tracking';

import { configureMetricsActions } from '@spotahome/marketplace-common/src/graphql/mutations/tenantActions';
import { getNumberActiveBanners } from '@spotahome/marketplace-common/src/components/HomeCardBanner/config';

import AdUrlService from '@spotahome/marketplace-common/src/utils/ad-url-service';

import InformationToastWrapper from '@spotahome/marketplace-common/src/components/InformationToast/index';
import ListingUnavailableModal from '@spotahome/marketplace-common/src/components/ListingUnavailableModal';
import OptInModalWrapper from '@spotahome/marketplace-common/src/components/OptInModalWrapper';
import SahContainer from '@spotahome/marketplace-common/src/components/SahContainer';

import CurrencyFormatter from '@spotahome/marketplace-common/src/utils/CurrencyFormatter';
import JsonLdOrganization from '@spotahome/marketplace-common/src/LEGACY/javascripts/microdata/components/JsonLdOrganization';
import { isSeoMode } from '@spotahome/marketplace-common/src/utils/seo-mode';

import { HighlightMarkersProvider } from '@spotahome/marketplace-common/src/context/HighlightMarkersContext';

import '@spotahome/marketplace-common/src/scss/init.scss';

import NoSSR from 'react-no-ssr';

import IconLoading from '@spotahome/marketplace-common/src/LEGACY/javascripts/ui/icon/icon-loading';

import { FiltersProvider } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import {
  HOMECARD_MAP_CLOSE,
  HOMECARD_MAP_OPEN
} from '@spotahome/marketplace-common/src/channels';

import Search from './components/Search';

const LoginModal = lazy(() =>
  import('@spotahome/marketplace-common/src/components/login/LoginModal')
);

const MARKERS_PER_PAGE = 60;

const SpottyCatWrapper = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const showButton = () => setShow(true);
    const hideButton = () => setShow(false);
    Mediator.subscribe(HOMECARD_MAP_OPEN, hideButton);
    Mediator.subscribe(HOMECARD_MAP_CLOSE, showButton);

    return () => {
      Mediator.unsubscribe(HOMECARD_MAP_OPEN, hideButton);
      Mediator.unsubscribe(HOMECARD_MAP_CLOSE, showButton);
    };
  }, []);

  return show && <SpottyCat />;
};

const SearchPage = () => {
  const { [SearchPage.NAME]: requestedData } = useSoyuzData();

  const {
    city,
    poi,
    maxPriceInCity,
    initialFiltersFromQuery,
    initialMarkerList = [],
    initialHomecards = {},
    isUserLogged,
    numberOfMarkers,
    areas,
    metadata,
    showUnavailableModal,
    locationString,
    locationCoordinates,
    deviceType,
    initialCookie,
    preloadedHomecards,
    cityList,
    isSafari,
    internalLinking,
    seoSnippet,
    userHasFavorites,
    tenantType,
    sortingAlgorithm
  } = requestedData;
  const countryCode = city?.country?.countryIsoCode;
  const currency = new CurrencyFormatter(city?.currencyIsoCode);
  const IS_SEO_MODE = isSeoMode(initialFiltersFromQuery);
  const history = useHistory();

  useCoreWebVitals();
  useEffect(() => {
    configureMetricsActions();

    Metrics.actions.sendActionEvent(Metrics.actions.VIEW_SEARCH, {
      cityId: city?.id
    });
  }, [city?.id]);

  useEffect(() => {
    const { userId } = requestedData;
    if (userId && typeof dataLayer !== 'undefined') {
      dataLayer.push({
        userId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapCenter =
    typeof window !== 'undefined' &&
    AdUrlService.getQueryParamValue('mapCenter');

  return (
    <CookiesProvider initialCookie={initialCookie}>
      <ThemeProvider flags={{ newButtons: true }}>
        <SahContainer>
          <AppBanner source="search" deviceType={deviceType} />
          <ToastContainer />
          {!isUserLogged && <GoogleOneTap />}
          {showUnavailableModal && <ListingUnavailableModal />}
          <InformationToastWrapper city={city?.id} />
          <UserInfoProvider>
            <CitiesProvider initialCityInfo={city} skipAllCities>
              <HighlightMarkersProvider>
                <HotspotsProvider>
                  <TenantTypeProvider tenantType={tenantType}>
                    <DeviceProvider deviceType={deviceType}>
                      <SpottyCatWrapper />
                      <FiltersProvider
                        initialFilters={initialFiltersFromQuery}
                        cityId={city?.id}
                        locationString={locationString}
                      >
                        <Search
                          locationString={locationString}
                          locationCoordinates={locationCoordinates}
                          history={history}
                          sessionPoi={poi}
                          city={city}
                          countryCode={countryCode}
                          currency={currency}
                          maxPriceInCity={maxPriceInCity}
                          seoMode={IS_SEO_MODE}
                          seoContent={metadata?.content}
                          initialMarkerList={initialMarkerList}
                          initialHomecards={initialHomecards}
                          numberOfMarkers={numberOfMarkers}
                          sortingAlgorithm={sortingAlgorithm}
                          itemsPerPage={
                            MARKERS_PER_PAGE - getNumberActiveBanners(city?.id)
                          }
                          areas={areas}
                          isUserLogged={isUserLogged}
                          map={initialFiltersFromQuery.map === 'true'}
                          mapCenter={mapCenter}
                          preloadedHomecards={preloadedHomecards}
                          cityList={cityList}
                          isSafari={isSafari}
                          internalLinking={
                            internalLinking.searchInternalLinking
                          }
                          seoSnippet={seoSnippet}
                          deviceType={deviceType}
                          userHasFavorites={userHasFavorites}
                        />
                      </FiltersProvider>
                    </DeviceProvider>
                  </TenantTypeProvider>
                </HotspotsProvider>
              </HighlightMarkersProvider>
            </CitiesProvider>
            <NoSSR>
              <Suspense fallback={<IconLoading />}>
                <LoginModal />
              </Suspense>
            </NoSSR>
          </UserInfoProvider>
          <OptInModalWrapper />
          <JsonLdOrganization description={trans('meta.description')} />
          <JsonLdHomecardListCarousel
            homecards={initialMarkerList
              .map(marker => initialHomecards[marker.id])
              .filter(Boolean)}
          />
        </SahContainer>
      </ThemeProvider>
    </CookiesProvider>
  );
};

SearchPage.NAME = 'SearchPage';

export default SearchPage;
